import React, { ReactNode } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ISubject } from "models/subject";
import { useTranslation } from "react-i18next";
import { StatusBadge } from "components/StatusTag";
import useGetAllData from "hooks/useGetAllData";
import dayjs from "dayjs";

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

const ViewSubject: React.FC<{data: ISubject | undefined}> = ({data}): JSX.Element => {
  const { t } = useTranslation();

  const { data: subjectCategories } = useGetAllData<{
    id: number;
    name: string;
    status: number;
  }>({
    queryKey: ["subject-categories"],
    url: `subject-categories`,
    urlParams: { "per-page": 0 },
  });

  const { data: examTypes } = useGetAllData<{
    id: number;
    name: string;
    status: number;
  }>({
    queryKey: ["exams-types"],
    url: `exams-types`,
    urlParams: { "per-page": 0 },
  });

  const sharedOnCell = (_: DataType, index: number | undefined) => {
    if (index || index == 0) {
      if (index < 2) {
        return { colSpan: 0 };
      }
    }
    return {};
  };

  const getName = (id: number | string, type: "ball" | "hour") => {
    if (type === "ball") {
      return examTypes?.items?.find((e) => e?.id == id)?.name;
    }
    if (type === "hour") {
      return subjectCategories?.items?.find((e) => e?.id == id)?.name;
    }
  };

  const getSillabus = (_data: string, type: "ball" | "hour") => {
    let a = 0;
    return (
      <>
        {Object.entries(JSON.parse(_data ?? "{}"))?.map(([key, value]: any, index: number) => {
          const name = getName(key, type);
          if (name) {
            a += value;
            return (
              <p key={index}>
                <span className="text-slate-400">{name}: </span>
                {value ?? ""} {t(type)}
              </p>
            );
          }
        })}
        <p>
          <span className="text-slate-400">{t("total " + type)}: </span>
          {a} {t(type)}
        </p>
      </>
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "name",
      rowScope: "row",
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: !(index == 0 || index == 1) ? 1 : 3,
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => sharedOnCell(_, index),
      className: "bg-[#FAFAFA]",
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => sharedOnCell(_, index),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Name"),
      value: data?.name,
    },
    {
      name: t("Description"),
      value: data?.description,
    },
    {
      name: t("Kafedra"),
      value: data?.kafedra?.name,
      value2: t("Semestr"),
      value3: data?.semestr?.name,
    },
    {
      name: t("Edu form"),
      value: data?.eduForm?.name,
      value2: t("Edu type"),
      value3: data?.eduType?.name,
    },
    {
      name: t("Credit"),
      value: data?.credit,
      value2: t("Status"),
      value3: <StatusBadge status={data?.status} />,
    },
    {
      name: t("Score distribution"),
      value: getSillabus(data?.edu_semestr_exams_types ?? "{}", "ball"),
      value2: t("Distribution of hours"),
      value3: getSillabus(
        data?.edu_semestr_subject_category_times ?? "{}",
        "hour"
      ),
    },
    {
      name: t("CreatedBy"),
      value: (
        <div>
          <span className="text-gray-400">
            {t("name")}/{t("Last Name")}/{t("Role")} :{" "}
          </span>
          {data?.createdBy?.first_name} {data?.createdBy?.last_name}{" "}
          (
          {data?.createdBy?.role.map((item: string) => {
            return item;
          })}
          )
          {/* <p>
            <span className="text-gray-400">{t("Login")}: </span>
            {data?.createdBy?.username}
          </p> */}
          <time className="block">
            <span className="text-gray-400">{t("Date")}: </span>
            {dayjs.unix(Number(data?.created_at)).format('MM-DD-YYYY')}
          </time>
        </div>
      ),
      value2: t("UpdateBy"),
      value3: (
        <div>
          {data?.updatedBy ? (
            <>
              <span className="text-gray-400">
                {t("name")}/{t("Last Name")}/{t("Role")} :{" "}
              </span>
              {data?.updatedBy?.first_name}{" "}
              {data?.updatedBy?.last_name} (
              {data?.updatedBy?.role.map((item: string) => {
                return item;
              })}
              )
              {/* <p>
                <span className="text-gray-400">{t("Login")}: </span>
                {data?.updatedBy?.username}
              </p> */}
              <time className="block">
                <span className="text-gray-400">{t("Date")}: </span>
                {dayjs.unix(Number(data?.updated_at)).format('MM-DD-YYYY')}
              </time>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="px-[24px] py-[20px]">
        <div className="table-none-hover">
          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            showHeader={false}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSubject;
